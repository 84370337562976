<template>
  <div class="none-border" v-loading="loading2">
    <div class="top_btns">
      <div class="left_btns">
        <el-input size="small" style="width:120px" v-model="listQuery.voucherNo" placeholder="请输入凭证号" @keyup.enter="getList" clearable></el-input>
        <qzf-period v-model:period="listQuery.beginTime" :placeholder="'会计账期起'" :clearable="true" style="width: 110px;"></qzf-period>
          <span>-</span>
        <qzf-period v-model:period="listQuery.endTime" :placeholder="'会计账期止'" :clearable="true" style="width: 110px;"></qzf-period>
        <el-button size="small" type="primary"   @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon> <span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:21px 0px 0px 16px;width:430px" class="styleForm">  
            <!-- <el-form-item label="会计期间 ：" :label-width="formLabelWidth">   
              <qzf-period v-model:period="listQuery.beginTime" :hidden="true"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime" style="margin-left: 4px;" :hidden="true"></qzf-period>
            </el-form-item> -->
            <el-form-item label="凭证来源 ：" :label-width="formLabelWidth">
              <el-select v-model="listQuery.fromType" placeholder="请选择来源" size="small" style="width:234px">
                <el-option v-for="item in fromOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_btn">
        <qzf-button jz="false"  size="small" @click="dayin()" type="success">
          <el-icon><Printer /></el-icon> <span  >打印</span>
        </qzf-button>
        <qzf-button jz="false" size="small" @click="daochuVoucher()" type="primary">
          <el-icon><FolderOpened /></el-icon> <span  >导出</span>
        </qzf-button>
      </div>
    </div>
    <div>
      <el-table stripe :data="list" @sort-change="changeSort" v-loading="loading1" style="width: 100%"  border :height="contentStyleObj" @selection-change="handleSelectionChange" ref="tableDate">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" type="selection" width="55" />
        <el-table-column align="center" prop="voucherNo" label="凭证字号" width="70">
          <template #default="scope">
            <el-tooltip content="点击查看凭证" placement="top"  effect="dark">
              <span @click="handleUpdate(scope.row)" style="cursor:pointer;color:#39b0d2">
                <i class="iconfont icon-pingzheng"></i>
                <span style="font-size:13px;margin-left:5px;">{{ scope.row.voucherNo }}</span>
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" sortable prop="voucherDate" label="日期" width="100">
          <template #default="scope">
            <span>{{ $parseTime(scope.row.voucherDate, "{y}-{m}-{d}")}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="摘要" width="250">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.voucherItem" :key="index">
              <el-tooltip
                 effect="dark"
                :disabled="isShowTooltip"
                placement="top-start"
                >
                <template #content>
                  <div style="max-width: 300px;">
                    {{ item.summary }}
                  </div> 
                </template>
                <span :ref="item.summary" @mouseover="onMouseOver(item.summary)">{{item.summary}}</span> 
              </el-tooltip>
            </p>
          </template>
        </el-table-column>

        <el-table-column align="center" label="科目" width="250">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.voucherItem" :key="index">
              <el-tooltip
                class="item"
                 effect="dark"
                placement="top-start"
                :disabled="isShowTooltip" 
                raw-content
                >
                <template #content>
                  <div style="max-width: 500px;">
                    {{item.fzhsName?item.subjectName+'--辅助核算：'+item.fzhsName:item.subjectName}}
                  </div> 
                </template>
                <span :ref="item.subjectName + item.fzhsName" @mouseover="onMouseOver(item.subjectName + item.fzhsName)">{{item.fzhsName?item.subjectName+'--辅助核算：'+item.fzhsName:item.subjectName}}</span> 
              </el-tooltip>
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="借方">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.voucherItem" :key="index">
              {{item.inAmount? $comdify(item.inAmount):""}}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="贷方">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.voucherItem" :key="index">
              {{item.outAmount?$comdify(item.outAmount):""}}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="billCount" label="附件张数" width="60"></el-table-column>
        <el-table-column align="center" prop="fromType" label="凭证来源"  width="100">
          <template #default="scope">
            <span>{{$VoucherSourceFilter(scope.row.fromType)}}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="createName" label="制单人" width="80">
          <template #default="scope">
            <span>{{scope.row.createdBy == 1 ? '系统自动' : scope.row.createName}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="checkName" label="审核人" width="80"></el-table-column>
        <!-- <el-table-column align="center" prop="checkStatus" label="审核状态"></el-table-column> -->
        <el-table-column label="审核状态" align="center" width="80"> 
          <template #default="scope">
            <div v-if="scope.row.checkStatus == 0">
              <i class="iconfont icon-gantanhao" style="margin-right: 4px;font-size: 13px;"></i>未审核
            </div>
            <div v-else>
              <i class="iconfont icon-duihao" style="margin-right: 4px;font-size: 13px;"></i>已审核
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <div class="bottom_button">
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="voucher"/>
      </div>
    </div>
  </div>
  <exportFile ref="exportFile" :listIds="allselect" name="chronological_account" :listQuery1="this.listQuery"></exportFile>
  <printVouchPz ref="printVouchPz" :listIds="allselect" name="chronological_account" :listQuery1="this.listQuery"></printVouchPz>
</template>

<script>
import { voucherList} from "@/api/voucher.js";
import printVouchPz from "./printVouchPz.vue";
import exportFile from "./exportFile.vue";
import {accountBook } from "@/api/printSet";
import { VOUCHER_SOURCE_OPTION } from '@/utils/commonData'
export default {
  name: "voucher",
  components:{
    printVouchPz,
    exportFile
  },
  data() {
    return {
      loading: false,
      loading1: false,
      loading2: false,
      listQuery:{
        comId: this.$store.getters['user/comInfo'].comId * 1,
        orderBy: "period, replace(voucher_no,'记','')+0",
        limit:50,
        page:1,
        checkStatus:0,
        itemName:'',
        startMoney:"",
        endMoney:""
      },
      list: [],
      total: 0,
      select: [],
      allselect:[],
      allselect1: [],
      allselect2: [],
      fromOptions:VOUCHER_SOURCE_OPTION,
      formLabelWidth: "100px",
      overVoucher:false,
      modeType:true,
      isShowTooltip:true,
      setScrollTop:'',
      setScrollTopFalse:""
    }
  },
  deactivated(){
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(245)
    this.getList()
  
  },
  computed: {
    // 是否全选
    isAllSelected() {
      //console.log(1);
      if(this.list.length == 0){
        return false
      }
      return this.list.every(el => {
        return el.checked;
      });
    },
  },
  methods:{
     //单选
     singleSelect(row,index) {
      row.checked = !row.checked;
      this.list.splice(index, 1, row);
      this.screenChecked()
    },
    // 全选、全不选
    allSelect() {
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.list = this.list.map(el => {
        el.checked = checked;
        return el;
      });
      this.screenChecked()
    },
    screenChecked(){
      this.select = this.list.filter(v=>{return v.checked})//所有
      let arr = []
      this.select.map(v=>{
        arr.push(v.id)
      })
      this.allselect = arr;//id push
      let arr1 = [];
      this.select.map(e=>{
        arr1.push({id:e.id,checkStatus:1});
      })
      this.allselect1 = arr1;//checkStatus1
      let arr2 = [];
      this.select.map(v=>{
        arr2.push({id:v.id,checkStatus:0});//checkStatus 0
      })
      this.allselect2 = arr2;
    },
    handleSelectionChange(e){
      this.select = e
      let arr = [];
      e.map(v => {
        arr.push(v.id);
      });
      this.allselect = arr;
      let arr1 = [];
      e.map(v => {
        arr1.push({id:v.id,checkStatus:1});
      });
      this.allselect1 = arr1;
      let arr2 = [];
      e.map(v => {
        arr2.push({id:v.id,checkStatus:0});
      });
      this.allselect2 = arr2;
    },
    daochuVoucher(){
      this.$refs.exportFile.init()
    },
    changeSort(e){
      if(e.order == 'descending'){
        this.descending = 'desc'
        this.listQuery.orderBy = "period,voucher_date,replace(voucher_no,'记','')+0 desc"
      }else{
        this.descending = ''
        this.listQuery.orderBy = "period,voucher_date,replace(voucher_no,'记','')+0"
      }
      this.getList();
    },
    dayin() {
      if(this.allselect.length == 0){
        this.$message.error('请至少选择一张要打印的凭证');
        return
      }
      let params = {
        ids:this.allselect,
        bookNames:["chronological_account"],
        query:this.listQuery,
        comIds: [this.$store.getters['user/comInfo'].comId*1]
      }
      accountBook(params).then(res=>{
        if(res.data.msg == 'success'){
          if (res.data.data.msg) {
           this.$qzfMessage(res.data.data.msg);
          } else {
            window.open(res.data.data.path);
          }
        }
      })
      return
      this.$refs.printVouchPz.init()
    },
    getList(){
      this.loading1 = true
      voucherList(this.listQuery).then(res=>{
        this.loading1 = false
        if(res.data.msg == 'success'){
          this.list = res.data.data.list ? res.data.data.list : [];
          this.list.map(v=>{
            v.checked = false
          })
        }
        this.select = [];
        this.allselect = [];
        this.allselect1 = [];
        this.allselect2 = [];
        this.total = res.data.data.total
      })
    },
    xx(period, list){
      let s = ""
      for (let index = 0; index < list.length; index++) {
        let element = list[index];
        if(element.label != '初始账期'){
          for (let index = 0; index < element.options.length; index++) {
            let element2 = element.options[index];
            if(element2.period == period){
              s = element.label
            }
          }
        } 
      }
      return s
    },
    handleUpdate(row){
      let voucherId = row.id
      let ops = this.$store.getters['commons/periods'];
      let lll = this.xx(row.period,ops)
      this.$store.dispatch('commons/getSubjects', this.listQuery.comId)
      if(lll != "正常账期"){
        this.$store.dispatch('commons/setParam', {historyVoucherId: voucherId,hisVoucherPeriod: row.period})
        this.$store.dispatch('tagsView/delCachedViewByName', "historyVoucher")
        this.$router.push({
          path: "/bookkeeps/components/historyVoucher",
          name: "historyVoucher",
        });
      }else{
        this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
        this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
        this.$router.push({
          path: "/bookkeeps/addVoucher",
          name: "addVoucher"
        });
      }
    },
    onMouseOver(str){
      let contentWidth = this.$refs[str].offsetWidth;
        // 判断是否开启tooltip功能
      if (contentWidth > 243) {
        this.isShowTooltip = false;
      } else {
        this.isShowTooltip = true;
      }
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        comId: this.$store.getters['user/comInfo'].comId * 1,
        orderBy: "period, replace(voucher_no,'记','')+0",
        limit:originLimit,
        page:1,
        checkStatus:0,
        itemName:'',
        startMoney:"",
        endMoney:""
      }
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
.el-table :deep(.cell){
  color: #212529;
}
.none-border p {
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #b9b9b9;
    text-indent: 1em;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .none-border p:first-child {
    border-top: none;
  }
.top_btns {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.styleForm{
  .el-select{
    margin-left: 0px;
  }
}
.bottom_button{
  display: inline-block;
  margin-top: 20px;
}
</style>
<style>
.none-border .cell {
  padding: 0;
}
.none-border .el-table__row td {
  padding: 0 !important;
}

.none-border .cell {
  padding: 0;
  text-align: center;
}
</style>

