<template>
  <div style="position:relative">
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick"  class="elTabsClass">
      <el-tab-pane label="科目余额表" name="1">
        <subjectBalance ref="subjectBalance" @success="tiaozhuan" ></subjectBalance>
      </el-tab-pane>
      <el-tab-pane label="核算项目余额表" name="2" lazy>
        <fzhsSubjectBalance ref="fzhsSubjectBalance" @success="tiaozhuan"></fzhsSubjectBalance>
      </el-tab-pane>
      <el-tab-pane label="凭证汇总" name="3" lazy>
        <proofCollect ref="proofCollect" ></proofCollect>
      </el-tab-pane>
      <el-tab-pane label="凭证序时账" name="9" lazy>
        <journalVoucher ref="journalVoucher" ></journalVoucher>
      </el-tab-pane>
      <el-tab-pane label="总账" name="4" lazy>
        <generalLedger ref="generalLedger" @success="tiaozhuan"></generalLedger>
      </el-tab-pane>
      <el-tab-pane label="明细账" name="5" lazy>
        <detailAccount ref="detailAccount" ></detailAccount>
      </el-tab-pane>
      <el-tab-pane label="核算项目明细账" name="6" lazy>
        <fzhsDetailAccount ref="fzhsDetailAccount" ></fzhsDetailAccount>
      </el-tab-pane>
      <el-tab-pane label="多栏账" name="7" lazy>
        <multicolumnAccount ref="multicolumnAccount" ></multicolumnAccount>
      </el-tab-pane>
      <el-tab-pane label="日记账" name="8" lazy>
        <dayBook ref="dayBook" ></dayBook>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import subjectBalance from "./components/subjectBalance.vue"
import fzhsSubjectBalance from "./components/fzhsSubjectBalance.vue"
import proofCollect from "./components/proofCollect.vue"
import detailAccount from "./components/detailAccount.vue"
import generalLedger from "./components/generalLedger.vue"
import fzhsDetailAccount from "./components/fzhsDetailAccount.vue"
import multicolumnAccount from "./components/multicolumnAccount.vue"
import dayBook from "./components/dayBook.vue"
import journalVoucher from "./components/journalVoucher.vue"
export default {
  name: "accountBook",
  components:{
    subjectBalance,
    fzhsSubjectBalance,
    proofCollect,
    detailAccount,
    generalLedger,
    fzhsDetailAccount,
    multicolumnAccount,
    dayBook,
    journalVoucher
  },
  data() {
    return{
      activeName:'1'
    }
  },
  mounted(){
    if(this.$route.query.subjectId){
      this.tiaozhuan(this.$route.query)
    }
  },
  methods:{
    handleClick(tab,e){
      if(tab.props.label == '科目余额表'){
        // this.$refs.subjectBalance.getList()
      }else if(tab.props.label == '核算项目余额表'){
        // this.$refs.fzhsSubjectBalance.getList()
      }else if(tab.props.label == '凭证汇总'){
        // this.$refs.proofCollect.getList()
      }else if(tab.props.label == '总账'){
        // this.$refs.generalLedger.getList()
      }else if(tab.props.label == '明细账'){
        // this.$refs.detailAccount.getList(e)
      }else if(tab.props.label == '核算项目明细账'){
        // this.$refs.fzhsDetailAccount.getList()
      }else if(tab.props.label == '多栏账'){
        setTimeout(() => {
          this.$refs.multicolumnAccount.getList()
        })
      }else if(tab.props.label == '日记账'){
        // this.$refs.dayBook.getList()
      }
    },
    tiaozhuan(e,period){
      //console.log(e);
      let param = {
        props:{
          label:'明细账',
        },
      }
      this.activeName = '5'
      if(e.remark == '辅助核算'){
        param = {
          props:{
            label:'核算项目明细账',
          },
        }
        this.activeName = '6'
        this.handleClick(param)
        setTimeout(() => {
          this.$refs.fzhsDetailAccount.getList(e)
        })
        
      }else{
        this.handleClick(param)
        setTimeout(() => {
          this.$refs.detailAccount.getList(e,period)
        })
      }
    }
  }
}
</script>

<style>

</style>
<style lang="scss">
.elTabsClass{
  .el-tabs__item{
    font-weight: 400 !important;
  }
} 
</style>