<template>
  <el-dialog :close-on-click-modal="false" v-model="dialogFormVisible" title="税款确认" width="450px" append-to-body>
    <el-form :model="form" size="small">
      <el-form-item label="确认状态:" required>
        <el-radio-group v-model="form.status">
          <el-radio :label="3">申报并扣款</el-radio>
          <el-radio :label="9">申报暂不扣款</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="createSure" :loading="loadinng">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus'
import { confirmAutomatic } from '@/api/automatic'
const { proxy } = getCurrentInstance()
const emit = defineEmits(['success'])
const form = ref({
  period: "",
  type: "skqr",
  status: 3
})
const comIds = ref([])
const dialogFormVisible = ref(false)
const loadinng = ref(false)
const init = (item, date) => {
  comIds.value = item.map(v => { return v.comId })
  form.value = {
    period: date,
    type: 'skqr',
    status: 3
  }
  dialogFormVisible.value = true
}
const createSure = () => {
  loadinng.value = true
  let arr = []
  comIds.value.map(v => {
    arr.push({
      comId: v,
      ...form.value
    })
  })
  confirmAutomatic(arr).then(res => {
    loadinng.value = false
    if (res.data.msg == 'success') {
      ElMessage.success('设置成功')
      dialogFormVisible.value = false
      emit('success')
      proxy.$bus.emit('confirmStatusUpdate')
    }
  })
}
defineExpose({ init })
</script>

<style scoped lang="scss"></style>
