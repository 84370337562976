<template>
  <div class="app-container auto_home">
    <div class="auto_content">
      <div class="auto_content_size">
        <div style="width: 50px">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_text">
            <div>企业总数</div>
            <span>{{ info.countTotal }}</span>家
          </div>
        </div>
        <div class="each" @click="getNewListTotal()">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>本期用户</div>
            <a>
              <span>{{ info.countPeriod }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="getWjz">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>异常</div>
            <a><span>{{ info.countErr }}</span>家</a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(2)">
          <div class="icon_bg">
            <i class="iconfont icon-shouquanyemian1"></i>
          </div>
          <div class="each_text">
            <div>待确认</div>
            <a>
              <span>{{ info.countDqr }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(1)">
          <div class="icon_bg">
            <i class="iconfont icon-jieyuekehu1"></i>
          </div>
          <div class="each_text">
            <div>已确认</div>
            <a>
              <span>{{ info.countYqr }}</span>家
            </a>
          </div>
        </div>
      </div>
      <div class="date_select">
        <qzf-search-period v-model:period="listQuery.period" :clearable="false" style="width: 100px"
          @change="getNewList"></qzf-search-period>
      </div>
    </div>
    <div class="content_select">
      <div class="left_select">
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px" size="small"
          @keyup.enter="getList" clearable /><el-button type="primary" @click="getList" size="small" icon="Search"
          class="search_btn">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin: 11px 0px 0px 15px; width: 300px" label-width="95px">
            <el-form-item label="纳税人类型：">
              <selecttaxtype v-model:type="listQuery.type" class="w-140"></selecttaxtype>
            </el-form-item>
            <el-form-item label="税局：">
              <selectcity v-model:citys="listQuery.districtCode" class="w-140"></selectcity>
            </el-form-item>
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" class="w-140"></selectuser>
            </el-form-item>
          </el-form>
        </search>
        <el-radio-group v-model="listQuery.not0" style="margin-left:5px" size="small" @change="getList">
          <el-radio-button :label="0">全部</el-radio-button>
          <el-radio-button :label="1">有税款</el-radio-button>
          <el-radio-button :label="2">无税款</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button @click="confirmAll()" type="success" size="small" icon="Checked"
          :disabled="!$buttonStatus('zdjz_skqr')">申报确认
        </el-button>
        <el-button @click="confirmAllKk()" type="primary" plain size="small" icon="Checked"
          :disabled="!$buttonStatus('zdjz_skqr')">扣款确认
        </el-button>
        <el-button type="primary" size="small" @click="copyTax" :disabled="!$buttonStatus('sktj_fzsk')"
          icon="DocumentCopy">复制税款
        </el-button>
        <el-button-group style="margin-left:12px">
          <el-button type="success" size="small" @click="sendTax" :loading="sendLoading"
            :disabled="!$buttonStatus('sktj_fzsk')"><i class="iconfont icon-weixin"></i>&nbsp;微信群发确认
          </el-button>
          <el-button type="success" size="small" @click="bindWechat" :disabled="!$buttonStatus('sktj_fzsk')">绑定微信
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div>
      <el-table :data="tableData" border style="width: 100%" v-loading="loading" :height="contentStyleObj"
        @selection-change="handleSelectionChange" stripe @sort-change="sortChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column label="编号" align="center" width="80" fixed="left" prop="sort" sortable="custom">
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="企业名称" min-width="280" fixed="left">
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId" :showAuthIcon="false">
              <template v-slot>
                <el-tooltip placement="top" effect="dark">
                  <template #content>
                    <div>{{ scope.row.wxNc ? `已绑定：${scope.row.wxNc}` : '暂未绑定微信昵称或群聊名称，点击绑定' }}</div>
                  </template>
                  <i class="iconfont icon-weixintuisong" :style="{ color: !scope.row.wxNc ? '#888' : '' }"
                    @click="setWechat(scope.row)"></i>
                </el-tooltip>
              </template>
            </TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column prop="districtName" label="税局" width="65" align="center">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district) }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="微信昵称" width="100" prop="wxNc">
        </el-table-column> -->
        <el-table-column prop="total" label="税款合计" sortable align="right" min-width="90">
          <template #default="scope">
            <span style="font-weight: 600;">{{ scope.row.total }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="zzs" label="增值税" sortable align="right" min-width="90" />
        <el-table-column prop="fjs" label="附加税" sortable align="right" min-width="90" />
        <el-table-column prop="deed" label="行为税" sortable align="right" min-width="90" />
        <el-table-column prop="gs" label="个税" sortable align="right" min-width="90" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.gs }}
            <span v-if="scope.row.gsFjs && scope.row.gsFjs != '0' && scope.row.gsFjs != '0.00'">+{{ scope.row.gsFjs
              }}</span>
            <span v-if="scope.row.gsOnly && scope.row.gsOnly != '0' && scope.row.gsOnly != '0.00'">+{{ scope.row.gsOnly
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qysds" label="企业所得税" sortable align="right" min-width="115" />
        <!-- <el-table-column
          prop="sb"
          label="社保"
          sortable
          align="right"
          min-width="90"
        /> -->
        <el-table-column prop="dqde" label="定期定额" sortable align="right" min-width="95" />
        <el-table-column prop="qt" label="其他税种合计" align="left" min-width="140">
          <template #default="scope">
            <div class="item_icon" style="justify-content: space-between">
              <div>
                {{
                  (
                    Number(scope.row.scjysds) +
                    Number(scope.row.whsyjss) +
                    Number(scope.row.ghjf) +
                    Number(scope.row.ljf) +
                    Number(scope.row.xfs) +
                    Number(scope.row.cbj) +
                    Number(scope.row.sl) +
                    Number(scope.row.hbs) +
                    Number(scope.row.qt)
                  ).toFixed(2)
                }}
              </div>
              <el-button @click="checkDetail(scope.row)" size="small" link>详情</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="申报确认" width="90">
          <template #default="scope">
            <div class="item_icon">
              <i :class="$filterAutoStatusIcon(scope.row.skqr_status)"></i>
              <span>{{
                $filterAutoStatusText(scope.row.skqr_status, "skqr")
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="确认人" width="90">
          <template #default="scope">
            <div v-if="scope.row.skqr_status == 3">
              {{
                scope.row.taxConfirmUserName
                  ? scope.row.taxConfirmUserName
                  : "系统自动"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="扣款确认" width="90">
          <template #default="scope">
            <div class="item_icon">
              <i :class="$filterAutoStatusIcon(scope.row.kkqr_status)"></i>
              <span>{{
                $filterAutoStatusText(scope.row.kkqr_status, "qr")
              }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="操作" min-width="130" fixed="right">
          <template #default="scope">
            <el-button size="small" plain type="primary"
              :disabled="scope.row.skqr_status != 2 || !$buttonStatus('zdjz_skqr')" @click="confirm(scope.row)"
              icon="Checked">
              确认
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList()" />
    </div>
  </div>
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogVisible" title="税款详情" width="500px">
    <table class="content content_head" cellspacing="0" width="100%">
      <tr class="top_bg">
        <td style="width: 60%">税种</td>
        <td style="width: 40%">税额</td>
      </tr>
      <tr>
        <td>生产经营所得税</td>
        <td>{{ taxInfo.scjysds }}</td>
      </tr>
      <tr>
        <td>文化事业建设费</td>
        <td>{{ taxInfo.scjysds }}</td>
      </tr>
      <tr>
        <td>工会经费</td>
        <td>{{ taxInfo.ghjf }}</td>
      </tr>
      <tr>
        <td>垃圾费</td>
        <td>{{ taxInfo.ljf }}</td>
      </tr>
      <tr>
        <td>消费税</td>
        <td>{{ taxInfo.xfs }}</td>
      </tr>
      <tr>
        <td>残保金</td>
        <td>{{ taxInfo.cbj }}</td>
      </tr>
      <tr>
        <td>水利基金</td>
        <td>{{ taxInfo.sl }}</td>
      </tr>
      <tr>
        <td>环保税</td>
        <td>{{ taxInfo.hbs }}</td>
      </tr>
      <tr>
        <td>其他税种</td>
        <td>{{ taxInfo.qt }}</td>
      </tr>
    </table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="dialogVisible = false">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <abnormalCom ref="abnormalCom" />
  <loginTaxAlert ref="loginTaxAlert" title="微信转发助手"></loginTaxAlert>
  <wechatMessage ref="wechatMessage" @success="getList" />
  <taxVerifySelect ref="taxVerifySelect" @success="getList" />

</template>

<script>
import { currentAccountPeriod } from "@/utils";
import { companyTaxAmountList, companyEaSetWxNc } from "@/api/company";
import { automaticStatTax,confirmAutomatic } from "@/api/automatic";
import selectcity from "@/components/Screening/selectcity";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import abnormalCom from "./components/abnormalCom.vue";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import wechatMessage from "./components/wechatMessage.vue";
import axios from "axios";
import taxVerifySelect from "./components/taxVerifySelect.vue";
export default {
  name: "taxConfirm",
  components: {
    selectcity,
    selecttaxtype,
    abnormalCom,
    TableSortCell,
    TagNameCopy,
    wechatMessage,
    taxVerifySelect
  },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        period: currentAccountPeriod(),
        type: "",
        autoMatic: 1,
        not0: 0,
      },
      loading: false,
      tableData: [],
      total: 0,
      selects: [],
      dialogVisible: false,
      taxInfo: {},
      info: {},
      taxes: {
        zzs: "增值税",
        fjs: "附加税",
        deed: "行为税",
        gs: "个税",
        qysds: "企业所得税",
        sb: "社保",
        dqde: "定期定额",
        scjysds: "生产经营所得税",
        whsyjss: "文化事业建设费",
        ghjf: "工会经费",
        ljf: "垃圾费",
        xfs: "消费税",
        cbj: "残保金",
        sl: "水利基金",
        hbs: "环保税",
        gsOnly: "全年一次性奖金",
        gsFjs: "劳务报酬"
      },
      sendLoading: false
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(260);
  },
  mounted() {
    this.getList();
    this.getInfo();
  },

  methods: {
    getList() {
      this.loading = true;
      companyTaxAmountList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    getInfo() {
      automaticStatTax(this.listQuery).then((res) => {
        if (res.data.msg == "success") {
          this.info = res.data.data;
        }
      });
    },
    getNewList() {
      this.getList();
      this.getInfo();
    },
    getNewListTotal(e) {
      this.listQuery.taxStatusTotal = e ? e : null;
      this.getNewList();
    },
    getWjz() {
      this.$refs.abnormalCom.init(this.listQuery.period);
    },
    // 筛选打印
    handleSelectionChange(val) {
      this.selects = val;
    },
    setWechat(row) {
      this.$prompt("请输入微信昵称/微信群名称", "绑定微信昵称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: row.wxNc,
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            let value = instance.inputValue
            let param = {
              ids: [row.comId],
              wxNc: value,
            }
            companyEaSetWxNc(param).then((res) => {
              done()
              if (res.data.msg == "success") {
                this.$qzfMessage('设置成功')
                this.getList()
              }
            })
          } else {
            done()
          }
        },
      })
    },
    //复制税款
    copyTax() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      // 基础字符串数组
      let rows = [];
      // 遍历selects数组
      this.selects.forEach((v) => {
        let row = `您的企业「${v.name}」${this.listQuery.period}账期`;
        if (v.total && v.total !== "0.00") {
          row += "税款如下：";
          Object.keys(this.taxes).forEach((key) => {
            const taxName = this.taxes[key];
            const taxValue = v[key];
            if (taxValue && taxValue !== "0" && taxValue !== "0.00" && taxName != '社保') {
              row += `\r${taxName}：${taxValue}`;
            }
          });
          row += `\r总计：${v.total}`;
        } else {
          row += "暂无税款";
        }
        rows.push(row);
      });
      // 将所有行拼接成最终字符串，注意最后的提示信息
      let str =
        rows.join("\r") +
        `\r${this.listQuery.period}账期税款如上，请确认，如有疑问请随时沟通`;
      this.$copyComName(str);
    },
    sendTax() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      let fail = this.selects.find(v => !v.wxNc)
      if (fail) {
        this.$qzfMessage(`${fail.name}未绑定微信，请重新选择`, 1)
        return
      }
      let that = this;
      this.sendLoading = true
      let arr = []
      this.selects.forEach((v => {
        let row = `您的企业「${v.name}」${this.listQuery.period}账期`;
        if (v.total && v.total !== "0.00") {
          row += "税款如下：";
          Object.keys(this.taxes).forEach((key) => {
            const taxName = this.taxes[key];
            const taxValue = v[key];
            if (taxValue && taxValue !== "0" && taxValue !== "0.00" && taxName != '社保') {
              row += `\n${taxName}：${taxValue}`;
            }
          });
          row += `\n总计：${v.total}`;
        } else {
          row += "暂无税款";
        }
        row += `\n${this.listQuery.period}账期税款如上，请确认，如有疑问请随时沟通`
        arr.push({
          wxName: v.wxNc,
          msg: row
        })
      }))
      this.sendLoading = false
      axios
        .post("http://localhost:8002/doTask/wx", arr)
        .then((res) => {
        })
        .catch(function () {
          setTimeout(() => {
            that.$refs.loginTaxAlert.dialogVisible = true;
          }, 1000);
        });
    },
    //确认
    confirm(row) {
      this.$refs.taxVerifySelect.init([row],this.listQuery.period)
    },
    //批量确认
    confirmAll() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      const arr = this.selects.filter(v => v.skqr_status == 2)
      if (arr && arr.length != 0) {
         this.$refs.taxVerifySelect.init(arr,this.listQuery.period)
      } else {
        this.$qzfMessage('您所勾选的公司中无待确认的税款信息', 1);
      }
    },
    //扣款确认
    confirmAllKk(){
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      const arr = this.selects.filter(v => v.kkqr_status == 2)
      if (arr && arr.length != 0) {
          this.$confirm("确定要批量扣款确认吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let ids = [];
            arr.map((v) => {
              ids.push({
                comId: v.comId,
                period: this.listQuery.period,
                type: "kkqr",
              });
            });
            confirmAutomatic(ids).then((res) => {
              if (res.data.msg == "success") {
                this.$qzfMessage("操作成功");
                this.getList();
                this.$bus.emit("confirmStatusUpdate");
              }
            });
          });
      } else {
        this.$qzfMessage('您所勾选的公司中不存在需扣款确认的公司', 1);
      }
    },
    //详情
    checkDetail(row) {
      this.taxInfo = row;
      this.dialogVisible = true;
    },
    // 表格排序
    sortChange(data) {
      if (data.column.label == "编号") {
        if (data.order == "descending") {
          this.listQuery.desc = 1;
        } else {
          this.listQuery.desc = 0;
        }
        this.getList();
      }
    },
    //绑定微信
    bindWechat() {
      this.$refs.wechatMessage.init()
    },
    //重置
    cancel() {
      let originLimit = this.listQuery.limit;
      this.listQuery = {
        page: 1,
        limit: originLimit,
        name: "",
        period: currentAccountPeriod(),
        type: "",
        autoMatic: 1,
        not0: 0,
      };
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.auto_content{
  margin: 0 0 5px;
}
.content_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  .left_select {
    display: flex;
    align-items: center;
  }
}

.item_icon {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  color: #333;

  i {
    font-size: 13px;
    margin-right: 3px;
  }
}

.content {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;

  tr {
    padding: 0;
    margin: 0;
  }

  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    line-height: 25px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
    text-align: center;
  }
}

.icon-weixintuisong {
  color: #15ba11;
  font-size: 13px;
  margin-left: 3px;
  cursor: pointer;
}
</style>
